<template>
  <div v-if="data">
    <e-charts
      class="w-100"
      ref="bar"
      autoresize
      :options="option"
      theme="theme-color"
      auto-resize
    />
  </div>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/bar";
import primaryTheme from "./theme.json";

ECharts.registerTheme("theme-color", primaryTheme);

const content = {
  components: {
    ECharts,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  computed: {
    option() {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: this.data.series.map((s) => s.name),
        },
        toolbox: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar", "stack"] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        xAxis: [
          {
            type: "category",
            name: "Date Range",
            axisTick: { show: false },
            data: this.data.xAxisData,
          },
        ],
        yAxis: [
          {
            name: "Revenue (INR)",
            type: "value",
          },
        ],
        series: this.data.series.map((s) => ({
          type: "bar",
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          ...s,
        })),
      };
    },
  },
};

export default content;
</script>
